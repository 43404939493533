// utils.js
import store from "./store.js";
import axios from "axios";

const SMART_GLASSES = "Smart Glasses";
const EXCLUSIVE = "Exclusive";
const NEW_ARRIVALS = "New Arrival";
const BESTSELLER = "Best Seller";
const PHOTOCHROMIC = "Photochromic";
const POLARIZED = "Polarized";
const BLUE_LIGHT_FILTER = "Blue Light Filter";
const SUSTAINABILE = "Sustainable";
const UNIVERSAL_FIT = "Universal Fit";
const KIDS = "Kids";

const gl_bestsellers = "gl_bestsellers";
const gl_bestsellers_Sunglasses = "gl_bestsellers_Sunglasses";
const gl_bestsellers_Eyeglasses = "gl_bestsellers_Eyeglasses";
const gl_newarrivals = "gl_newarrivals";
const gl_newarrivals_sunglasses = "gl_newarrivals_sunglasses";
const gl_newarrivals_eyeglasses = "gl_newarrivals_eyeglasses";
const gl_trends_Sustainable = "gl_trends_Sustainable";

export function createSearchByTermResponse(data, response) {
	var formattedResponse = [];
	if (response && response.data && response.data.catalogEntryGroupView) {
		const catalogEntryGroupView = response.data.catalogEntryGroupView;
		for (const key in catalogEntryGroupView) {
			if (Object.hasOwnProperty.call(catalogEntryGroupView, key)) {
				const moco = catalogEntryGroupView[key][0];
				let jsonElement = assembleResponse(moco);
				jsonElement.colors = catalogEntryGroupView[key].length;
				formattedResponse.push(jsonElement);
			}
		}
		return formattedResponse;
	} else {
		console.error("I dati della risposta non sono conformi alle aspettative.");
	}
}

export function createSearchByPartNumberResponse(response) {
	var formattedResponse = [];
	if (response && response.data && response.data.catalogEntryView) {
		const catalogEntryView = response.data.catalogEntryView;
		for (const key in catalogEntryView) {
			if (Object.hasOwnProperty.call(catalogEntryView, key)) {
				const moco = catalogEntryView[key];
				let jsonElement = assembleResponse(moco);
				formattedResponse.push(jsonElement);
			}
		}
	} else {
		console.error("I dati della risposta non sono conformi alle aspettative.");
	}
	return formattedResponse;
}

function assembleResponse(data) {
	let listPrice = data.sKUs[0].price.find((obj) => obj.description === "L")?.value;
	let offerPrice = data.sKUs[0].price.find((obj) => obj.description === "I")?.value;
	if (!!(store.state.data.earlyAccessEnabled.toLowerCase() === "true")) {
		const eaPrice = data.sKUs[0].price.find((obj) => obj.description === "EA");
		offerPrice = eaPrice && eaPrice.value ? eaPrice.value : offerPrice;
	}
	return {
		id: data.sKUs[0].uniqueID,
		style: data.attributes.find((element) => element.name == "MODEL_NAME")?.values[0].value,
		brand: data.manufacturer,
		colors: data.colors_variants_gl,
		img: createImgPath("001", data.sKUs[0].partNumber),
		img2: createImgPath("002", data.sKUs[0].partNumber),
		price: Number(listPrice).toFixed(2),
		offerPrice: Number(offerPrice).toFixed(2),
		discount: calculateDiscountPercentage(listPrice, offerPrice),
		partNumber: data.sKUs[0].partNumber,
		firstBadge: getFirstBadge(data),
		secondBadge: getSecondBadge(data),
		BrandUrl: getBrandUrl(data),
	};
}

export function createImgPath(imgType, partNumber) {
	return "https://assets.glasses.com/is/image/Glasses/" + partNumber + "__" + imgType + ".png?impolicy=GL_g-thumbnail-plp";
}

export function calculateDiscountPercentage(listPrice, offerPrice) {
	if (listPrice != offerPrice && Number(offerPrice) < Number(listPrice)) {
		return Number((((listPrice - offerPrice) * 100) / listPrice).toFixed(0));
	}
}

export function itemsForSize(desktopElements, mobileElements, tabletElements) {
	const screenWidth = window.innerWidth;
	if (screenWidth >= 0 && screenWidth <= 800) {
		return mobileElements;
	} else if (screenWidth > 800 && screenWidth <= 1024) {
		return tabletElements;
	} else if (screenWidth > 1024) {
		return desktopElements;
	}
}

export function suggestionForSize() {
	const screenWidth = window.innerWidth;
	if (screenWidth >= 0 && screenWidth <= 800) {
		return 3;
	} else if (screenWidth > 800 && screenWidth <= 1024) {
		return 4;
	} else if (screenWidth > 1024) {
		return 4;
	}
}

//call services

export const doSearchBySearchTerm = async (data, keyword) => {
	return new Promise((resolve, reject) => {
		const url = createUrlBySearchTerm(data, keyword);

		if (url) {
			axios
				.get(url, {
					params: {
						profileName: "RONA_findProductsAndSkusBySearchTerm",
						catalogId: data.catalogId,
						responseFormat: "json",
						pageSize: itemsForSize(5, 6, 6),
						pageNumber: 1,
						categoryId: data.sequenceCategoryId,
						facet: data.inStockFacet + ":1",
					},
					withCredentials: false,
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		}
	});
};

export const doSearchByPartNumber = async (data, partNumbers) => {
	return new Promise((resolve, reject) => {
		const url = createUrlByPartNumber(data, partNumbers);
		if (url) {
			axios
				.get(url, {
					params: {
						profileName: "GL_findProductByPartNumber_DetailsWithAttributes",
						catalogId: data.catalogId,
						responseFormat: "json",
						facet: data.inStockFacet + ":1",
					},
					withCredentials: false,
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		}
	});
};

export const getSuggestionsBySearchTerm = async (data, keyword) => {
	return new Promise((resolve, reject) => {
		const url = createSuggestionsUrl();

		if (url) {
			axios
			.get(url, {
				params: {
					storeId: data.storeId,
					catalogId: data.catalogId,
					terms: keyword,
				},
				withCredentials: false,
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		}
	});
};

export const getEspotByName = async (data, espotName) => {
	return new Promise((resolve, reject) => {
		const url = createEspotUrl(data, espotName);

		if (url) {
			axios
				.get(url)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		}
	});
};

function createUrlBySearchTerm(data, keyword) {
	switch (window.location.host) {
		case "localhost:8081":
			return "http://localhost:8080/search/resources/store/" + data.storeId + "/productview/bySearchTerm/" + keyword;
		default:
			return "https://" + window.location.host + "/search/resources/store/" + data.storeId + "/productview/bySearchTerm/" + keyword;
	}
}

function createSuggestionsUrl() {
	switch (window.location.host) {
		case "localhost:8081":
			return "https://developer.glasses.com/gl-us/KeywordSuggestionsByTerm";
		default:
			return "https://" + window.location.host + "/gl-us/KeywordSuggestionsByTerm";
	}
}

function createEspotUrl(data, espotName) {
	switch (window.location.host) {
		case "localhost:8081":
			return "http://localhost:8080/wcs/resources/store/" + data.storeId + "/espot/" + espotName;
		default:
			return "https://" + window.location.host + "/wcs/resources/store/" + data.storeId + "/espot/" + espotName;
	}
}

function createUrlByPartNumber(data, partNumbers) {
	let partNumbersString = "";
	if (!!partNumbers) {
		partNumbersString = formatPartNumbers(partNumbers);
	}
	switch (window.location.host) {
		case "localhost:8081":
			return "http://localhost:8080/search/resources/store/" + data.storeId + "/productview/byPartNumbers/" + partNumbersString;
		default:
			return "https://" + window.location.host + "/search/resources/store/" + data.storeId + "/productview/byPartNumbers/" + partNumbersString;
	}
}

function formatPartNumbers(partNumbers) {
	let partNumbersString = "?partNumber=" + partNumbers[0];
	for (let i = 1; i < partNumbers.length; i++) {
		partNumbersString += "&partNumber=" + partNumbers[i];
	}
	return partNumbersString;
}

export function splitPartNumbers(partNumbers) {
	return partNumbers.split(",").map((string) => string.trim());
}

export function trackAnalytics(id, Tracking_Type, data_element_id, data_description) {
	if (typeof tealium_data2track !== "undefined" && tealium_data2track !== null) {
		tealium_data2track.push({
			id: id,
			Tracking_Type: Tracking_Type,
			data_element_id: data_element_id,
			data_description: data_description,
		});
	}
}
function getFirstBadge(response) {
	if (!!response.attributes.find((element) => element.name == "PROD_HIERARCHY_1") && response.attributes.find((element) => element.name == "PROD_HIERARCHY_1")?.values[0].value == "Electronics") {
		return SMART_GLASSES;
	} else if (!!response.attributes.find((element) => element.name == "EXCLUSIVE_GL_US") && response.attributes.find((element) => element.name == "EXCLUSIVE_GL_US")?.values[0].value) {
		return EXCLUSIVE;
	} else if (findCategoryByCatGroupIds(response, gl_newarrivals) || findCategoryByCatGroupIds(response, gl_newarrivals_eyeglasses) || findCategoryByCatGroupIds(response, gl_newarrivals_sunglasses)) {
		return NEW_ARRIVALS;
	} else if (findCategoryByCatGroupIds(response, gl_bestsellers) || findCategoryByCatGroupIds(response, gl_bestsellers_Eyeglasses) || findCategoryByCatGroupIds(response, gl_bestsellers_Sunglasses)) {
		return BESTSELLER;
	}
}

function getBrandUrl(response) {
	if (!!response.attributes.find((element) => element.name == "PROD_HIERARCHY_1") && response.attributes.find((element) => element.name == "PROD_HIERARCHY_1")?.values[0].value == "Electronics") {
		return "ray-ban";
	} else {
		return "eyewear";
	} 
}

function getSecondBadge(response) {
	if (!!response.attributes.find((element) => element.name == "LENS_TREATMENT") && response.attributes.find((element) => element.name == "LENS_TREATMENT")?.values[0].value == "photochromic") {
		return PHOTOCHROMIC;
	} else if (!!response.attributes.find((element) => element.name == "POLARIZED") && response.attributes.find((element) => element.name == "POLARIZED")?.values[0].value) {
		return POLARIZED;
	} else if (!!response.attributes.find((element) => element.name == "BLUE_LIGHT_PROTECTION") && response.attributes.find((element) => element.name == "BLUE_LIGHT_PROTECTION")?.values[0].value) {
		return BLUE_LIGHT_FILTER;
	} else if (findCategoryByCatGroupIds(response, gl_trends_Sustainable)) {
		return SUSTAINABILE;
	} else if (!!response.attributes.find((element) => element.name == "GEOFIT") && (response.attributes.find((element) => element.name == "GEOFIT")?.values[0].value == "universal fit" || response.attributes.find((element) => element.name == "GEOFIT")?.values[0].value == "universal fitting")) {
		return UNIVERSAL_FIT;
	} else if (!!response.attributes.find((element) => element.name == "GEOFIT") && response.attributes.find((element) => element.name == "MACRO_AGE_RANGE")?.values[0].value == "children") {
		return KIDS;
	}
}

function findCategoryByCatGroupIds(response, category) {
	if (!!response && !!response.parentCatalogGroupID && !!category) {
		const categoryIds = response.parentCatalogGroupID;
		for (let i = 0; i < categoryIds.length; i++) {
			if (categoryIds[i].split("_")[1] == store.state.data.catGroupCatIdentifier[category]) return true;
		}
	}
}

export function sortByPartNumberResponse(response, partNumbersArray) {
	var mapPartNumber = {};
	partNumbersArray.forEach(function(partNumber, index) {
		mapPartNumber[partNumber] = index;
	});

	response.data.catalogEntryView.sort(function(a, b) {
		var indexA = mapPartNumber[a.partNumber];
		var indexB = mapPartNumber[b.partNumber];
	
		if (indexA === undefined && indexB === undefined) {
			return 0;
		} else if (indexA === undefined) {
			return 1;
		} else if (indexB === undefined) {
			return -1;
		}
		return indexA - indexB;
	});

	return response;
}
